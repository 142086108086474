import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }

    .marquee_main_slider_premature_ejaculation {
      z-index: 1;
    }

    .class_for_br {
      display: none;
    }
`;
