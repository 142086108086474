/* The code you provided is a JavaScript code snippet that sets up a React application using React's
lazy loading feature. */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LazyLoader } from "./components/LazyLoader";
import GlobalStyles from "./assets/css/global";
import "./assets/css/index";

/* The line `const App = LazyLoader(React.lazy(() => import("./App")));` is using React's lazy loading
feature to dynamically import the "./App" component. */
const App = LazyLoader(React.lazy(() => import("./App")));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyles />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
